.resultdisplay {
    color: white;
    font-family: monospace;
    font-size: 30px;

}

.cent {
    height: 800px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #121212;
}

.tabledesign {
    display: flex;
    flex-direction: block;
    justify-content: space-between;
}

.tableouter {
    display: block;
    flex-direction: row;
    align-items: center;
    margin-left: 20%;
    margin-right: 20%;
    justify-content: center;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .share {
    font-family: monospace;
    color: white;
    font-size: 20px;
}

.intro2 {
    color: black;
    align-items: center;
    justify-content: center;
    text-align: center;
}